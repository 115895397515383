import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { unGetItem } from '@/http/tableApi'
import { useSearch } from '@/use/Base/item/useSearch'
import { usePage } from '@/use/Base/item/usePage'
import { useFilterAndSort } from '@/use/Base/item/useFilterAndSort'
import { Alert } from '@/extension/Alert/Alert'

export function useGetItem(emit, perItem, options) {
  const store = useStore()
  const filterName = ref('')
  const sortName = ref(options.sort || '-published_at')
  const PER_ITEM = perItem || 9

  const { currentPage, handlerPage } = usePage(options, getItem, emit)
  const { searchInput } = useSearch(options, filterName, getItem)
  const { handlerSort, getFilter } = useFilterAndSort(
    options,
    filterName,
    sortName,
    emit,
    getItem,
  )

  onMounted(async () => {
    if (options.rows) return
    await getItem()
  })

  const getIsLoad = computed(() => {
    return store.getters['item/getIsLoad']
  })

  const getItemInStore = computed(() => {
    if (options.rows) return options.rows
    return store.getters['item/getItems']
  })

  watch(
    options,
    async () => {
      await getItem()
    },
    { deep: true },
  )

  async function getItem() {
    try {
      if (!options.urlSetRows) return store.commit('item/setIsLoad')
      store.commit('item/setIsLoad')
      const actionQuery = options.urlActionQuery
        ? `&${options.urlActionQuery}`
        : ''
      let filterQuery = ''
      const itemFilter = store.getters['filter/getFilter']
      if (itemFilter?.length) {
        for (let i = 0; i < itemFilter.length; i++) {
          filterQuery += `&search[${itemFilter[i].type}]=${itemFilter[i].value}`
        }
      }
      store.commit('item/setItems', [])
      const URL = `${options.urlSetRows}?page=${currentPage.value}${actionQuery}${filterQuery}${searchInput.value}&per-page=${PER_ITEM}&sort=${sortName.value}`
      const res = await unGetItem(options.requestIsAuth, URL)
      store.commit(
        'pagination/setMaxPage',
        res.headers['x-pagination-page-count'],
      )
      let data
      if (options.transformObject) {
        data = await options.transformObject(res.data.data)
      } else {
        data = res.data.data
      }

      store.commit('item/setItems', data)
    } catch (e) {
      await Alert.show('error', 'Не удалось получить данные')
    } finally {
      store.commit('item/setIsLoad')
    }
  }

  return {
    getItemInStore,
    getIsLoad,
    handlerPage,
    handlerSort,
    getItem,
    getFilter,
    sortName,
  }
}
