<template>
  <div class="form__control form__control-textarea">
    <label
      :for="id"
      :class="['label label-textarea', labelClass, disabled ? 'disabled' : '']"
    >
      {{ label }}
    </label>
    <textarea
      :class="['textarea', textAreaClass, IsLengthInput ? 'active' : '']"
      :name="name"
      :id="id"
      :disabled="disabled"
      :value="modelValue"
      @input="onInput"
    >
    </textarea>
  </div>
</template>

<script>
import { fieldProps } from '@/mixin/props'
import { computed, ref } from 'vue'

export default {
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      required: false,
      default: 'Примечание'
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    textAreaClass: {
      type: String,
      required: false
    },
    modelValue: {
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const value = ref('')

    const onInput = e => {
      emit('update:modelValue', e.target.value)
    }

    const IsLengthInput = computed(() => {
      return props.modelValue
    })

    return {
      value,
      onInput,
      IsLengthInput
    }
  },
  mixins: [fieldProps]
}
</script>
