<template>
  <div
    class="lot-favorites"
    @click.stop="lotFollowHandler"
  >
    <VIcon
      :class="['lot-favorites__icon', isChosen ? 'chosen' : '']"
      width="30"
      height="28"
      icon-name="FavoritesLot"
    />
    <span>
      {{ isChosen ? 'Избранно' : 'В избранное' }}
    </span>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { LotController } from '@/controllers/LotController'

export default {
  components: {
    VIcon
  },
  props: {
    lotId: {
      type: [Number, String],
      required: true
    },
    isFavorites: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const lotController = new LotController()
    const store = useStore()
    const isChosen = ref(props.isFavorites)

    const lotFollowHandler = async () => {
      const userId = store.getters['auth/getUser']?.id

      if (!isChosen.value === true) {
        await lotController.follow(props.lotId, userId)
      } else {
        await lotController.unfollow(props.lotId, userId)
      }

      isChosen.value = !isChosen.value
      store.commit('item/changeItem', {
        id: props.lotId,
        is_favorites: isChosen.value
      })
    }

    return {
      isChosen,
      lotFollowHandler
    }
  },
}
</script>
