import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'

export function useSearch (options, filterName, getItem) {
  const store = useStore()
  const searchInput = ref('')

  const getSearch = computed(() => {
    return store.getters['filter/getSearch']
  })

  watch(getSearch, async (val) => {
    let search = null
    if (!val) {
      if (options.rows) {
        return options.rows
      }
      filterName.value = null
      return await getItem()
    }
    for (let i = 0; i < val.length; i++) {
      search = `&search[${val[i].type}]=${val[i].value}`
    }
    searchInput.value = search
    store.commit('pagination/setCurrentPage', 1)
    await getItem()
  })

  return {
    searchInput,
    getSearch
  }
}
