const touchBarAllUsers = [
  {
    name: 'Удалить',
    emit: 'delete',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  },
  {
    name: 'Заблокировать',
    emit: 'ban',
    confirm: true,
    many: true,
    icon: {
      url: 'ban.svg',
      name: 'Block',
      width: 19,
      height: 19
    }
  },
  {
    name: 'Разблокировать',
    emit: 'pardon',
    confirm: true,
    many: true,
    icon: {
      url: 'pardon.svg',
      name: 'Pardon',
      width: null,
      height: null
    }
  }
]

const touchBarDelete = [
  {
    name: 'Удалить',
    emit: 'delete',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  }
]

const touchBarCategoryAdmin = [
  {
    name: 'Удалить',
    emit: 'delete',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  },
  {
    name: 'Активировать',
    emit: 'active',
    confirm: false,
    many: true,
    icon: {
      url: 'activate.svg',
      name: 'Activate',
      width: 18,
      height: 18
    }
  },
  {
    name: 'Деактивировать',
    emit: 'deactive',
    confirm: false,
    many: true,
    icon: {
      url: 'deactivate.svg',
      name: 'Deactivate',
      width: 18,
      height: 18
    }
  }
]

const defaultTouchBarList = [
  {
    name: 'Удалить',
    emit: 'delete',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  }
]

const lotTouchBarList = [
  {
    name: 'Опубликовать',
    emit: 'public',
    confirm: false,
    many: false,
    icon: {
      url: 'public.svg',
      name: 'Public',
      width: 12,
      height: 12
    }
  },
  {
    name: 'Открыть',
    emit: 'open',
    confirm: false,
    many: false,
    icon: {
      url: 'open.svg',
      name: 'OpenLot',
      width: 15,
      height: 15
    }
  },
  {
    name: 'Продлить',
    emit: 'extend',
    confirm: true,
    many: false,
    icon: {
      url: 'extend.svg',
      name: 'ExtendLot',
      width: 14,
      height: 14
    }
  },
  {
    name: 'Отмена',
    emit: 'cancel',
    confirm: false,
    many: false,
    icon: {
      url: 'cancel.svg',
      name: 'CancelLot',
      width: 15,
      height: 15
    }
  },
  {
    name: 'Скрыть',
    emit: 'hide',
    confirm: false,
    many: false,
    icon: {
      url: 'hide.svg',
      name: 'HideLot',
      width: 18,
      height: 14
    }
  },
  {
    name: 'Удалить',
    emit: 'delete',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  },
  {
    name: 'Удалить до публикации',
    emit: 'deleteBeforePublic',
    confirm: true,
    many: true,
    icon: {
      url: 'bucket.svg',
      name: 'Delete',
      width: 16,
      height: 20
    }
  }
]

const lotTouchBarRestore = [
  {
    name: 'Восстановить',
    emit: 'restore',
    confirm: true,
    many: false,
    icon: {
      url: 'extend.svg',
      name: 'ExtendLot',
      width: 14,
      height: 14
    }
  },
]

export {
  lotTouchBarList,
  defaultTouchBarList,
  touchBarCategoryAdmin,
  touchBarDelete,
  touchBarAllUsers,
  lotTouchBarRestore
}
