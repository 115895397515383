<template>
  <div class="touch-bar">
    <div :class="['touch-bar__inner', classInner]">
      <slot />
      <div
        class="touch-bar__item--box"
        v-for="touchBarItem in touchBarList"
        :key="touchBarItem.name"
        @click="controlFunction(touchBarItem)">

        <div v-if="!touchBarItem.many && selectedItems.length > 1"></div>

        <div
          v-else
          :class="['touch-bar__item', classTouchBarItem]">
          <VIcon
            :class="touchBarItem.icon.name"
            :width="touchBarItem.icon.width || 25"
            :height="touchBarItem.icon.height || 25"
            :icon-name="touchBarItem.icon.name"
          />
<!--          <img :src="require(`@/assets/icon/table/${touchBarItem.icon}`)" :alt="touchBarItem.name"/>-->
          <span :class="['touch-bar__description', classTouchBarDescription]">{{ touchBarItem.name }}</span>
        </div>

      </div>

    </div>

    <teleport to="body">
      <VModal
        v-if="getIsOpen"
        confirm
        :confirm-question="getQuestion"
        :max-width-class="selectedEmitName === 'delete' ? 'width-470' : 'width-550'"
        @close="handlerResult"
      >
        <template #confirm>
          <div v-if="visibleSlotConfirm" class="column width-100 gap-20 mb-20">
            <VTextarea
              v-if="visibleConfirmCause"
              :label="confirmCauseLabel"
              v-model="formDataConfirm.cause"
              name="reason"
              text-area-class="textarea-bg-transparent textarea-border-gray textarea-n-resize"
            />

            <VDatePicker
              v-if="visibleConfirmDate"
              placeholder="Выберете дату"
              v-model="formDataConfirm.date"
            />
          </div>
        </template>
      </VModal>
    </teleport>
  </div>
</template>

<script setup>
import { defineProps, defineExpose, defineEmits, ref, computed, reactive } from 'vue'
import VDatePicker from '@/components/ui/form/VDatePicker'
import VModal from '@/components/ui/modal/VModal'
import VIcon from '@/components/ui/icon/VIcon'
import VTextarea from '@/components/ui/form/VTextarea'
import { Alert } from '@/extension/Alert/Alert'

const emits = defineEmits(['action'])

const props = defineProps({
  touchBarList: {
    type: Array,
    required: true
  },
  selectedItems: {
    type: Array,
    required: false
  },
  classInner: {
    type: String,
    required: false
  },
  classTouchBarItem: {
    type: String,
    required: false
  },
  classTouchBarDescription: {
    type: String,
    required: false
  }
})

// Lets
const isOpen = ref(false)
const selectedEmitName = ref('')

// Function
const onEmit = (emitName, formData = {}) => {
  emits('action', emitName, props.selectedItems, formData)
}

// Function for modal
const controlFunction = (touchBarItem) => {
  if (!touchBarItem.confirm) return onEmit(touchBarItem.emit)
  isOpen.value = !isOpen.value
  selectedEmitName.value = touchBarItem.emit
}

const getIsOpen = computed(() => {
  return isOpen.value
})

const handlerResult = (boolean) => {
  if (!boolean) {
    isOpen.value = false
    return resetConfirm()
  }

  if (!isValidFormDataConfirm()) return

  isOpen.value = false

  if (visibleSlotConfirm.value) {
    onEmit(selectedEmitName.value, formDataConfirm)
  } else {
    onEmit(selectedEmitName.value)
  }

  resetConfirm()
}
function resetConfirm () {
  if (visibleSlotConfirm.value) {
    resetFormDataConfirm()
  }
  selectedEmitName.value = ''
}

const questions = new Map([
  ['banMany', 'Заблокировать выбранных пользователей?'],
  ['ban', 'Заблокировать выбранного пользователя?'],
  ['pardonMany', 'Разблокировать выбранных пользователей?'],
  ['pardon', 'Разблокировать выбранного пользователя?'],
  ['deleteMany', 'Удалить выбранные элементы?'],
  ['delete', 'Удалить выбранный элемент?'],
  ['deleteBeforePublic', 'Удалить до публикации?'],
  ['restoreMany', 'Восстановить выбранные лоты?'],
  ['restore', 'Восстановить выбранный лот?'],
  ['extend', 'Продлить выбранный лот?'],
])

const getQuestion = computed(() => {
  if (props.selectedItems.length > 1) {
    return questions.get(`${selectedEmitName.value}Many`)
  }
  return questions.get(selectedEmitName.value)
})

defineExpose({
  getIsOpen
})

const formDataConfirm = reactive({
  cause: null,
  date: null
})
function resetFormDataConfirm () {
  for (const key in formDataConfirm) {
    formDataConfirm[key] = null
  }
}

const visibleSlotConfirm = computed(() => {
  return visibleConfirmCause.value || visibleConfirmDate.value
})

// Confirm Cause
const visibleConfirmCause = computed(() => {
  switch (selectedEmitName.value) {
    case 'banMany':
    case 'ban':
    case 'pardonMany':
    case 'pardon': return true
    default: return false
  }
})
const confirmCauseLabel = computed(() => {
  switch (selectedEmitName.value) {
    case 'banMany':
    case 'ban': return 'Укажите причину блокировки'
    case 'pardonMany':
    case 'pardon': return 'Укажите причину разблокировки'
    default: return ''
  }
})

function isValidFormDataConfirm () {
  if (!visibleSlotConfirm.value) return true

  if (visibleConfirmCause.value && !formDataConfirm.cause) {
    Alert.show('warn', 'Укажите причину')
    return false
  }

  return true
}

// Confirm Date
const visibleConfirmDate = computed(() => {
  switch (selectedEmitName.value) {
    case 'extend': return true
    default: return false
  }
})
</script>
