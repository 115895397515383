import { computed } from 'vue'
import { useStore } from 'vuex'

export function usePage (options, getItem, emit) {
  const store = useStore()
  const currentPage = computed(() => {
    return store.getters['pagination/getCurrentPage']
  })

  const handlerPage = async (page) => {
    if (!options.rows) {
      store.commit('pagination/setCurrentPage', page)
      await getItem()
    } else {
      emit('page', page)
    }
  }

  return {
    handlerPage,
    currentPage
  }
}
