<template>
  <DatePicker
    :id="id"
    class="calendar calendar-yellow"
    color="gray"
    style="background: #FFED00; color: #233F1F;"
    v-model="date"
    locale="ru"
    :mode="mode"
    is24hr
    :max-date="max ? new Date(max) : MAX_DATEPICKER"
    :timezone="timezone"
    @click="setDate"
    @mousemove.prevent
    @hover.prevent
    @focus.prevent
    @mouseover.prevent
    @mouseenter.prevent
  >

    <template v-slot="{ inputValue, inputEvents }">
      <div :class="['form__control', classForm]">
      <input
        :class="['input width-100 calendar-input', getError ? 'error' : '', IsLengthInput ? 'active' : '']"
        :name="name"
        id="datePicker"
        :value="inputValue"
        v-on="inputEvents"
        @blur="setBlur"
        @input="setInput"
      />
        <label
          :class="['label--placeholder label--placeholder-date-picker', IsLengthInput ? 'active' : '']"
          for="datePicker">
          {{ placeholder }}
        </label>

        <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="calendar-icon">
          <rect x="0.75" y="3.26562" width="19.1286" height="17.9857" rx="1.25" stroke="#233F1F" stroke-width="1.5"/>
          <rect x="0.75" y="3.26562" width="19.1286" height="4.15714" rx="1.25" stroke="#233F1F" stroke-width="1.5"/>
          <rect x="5.29973" y="0.429762" width="4.79762" height="0.859524" rx="0.429762" transform="rotate(90 5.29973 0.429762)" stroke="#233F1F" stroke-width="0.859524"/>
          <rect x="16.1894" y="0.429762" width="4.79762" height="0.859524" rx="0.429762" transform="rotate(90 16.1894 0.429762)" stroke="#233F1F" stroke-width="0.859524"/>
        </svg>
        <label for="datePicker" :class="['label-valid calendar-label', getError ? 'error' : 'success']"></label>

        <div v-if="getError">
          <small class="error-small" v-if="errors[0]?.$message">{{ errors[0]?.$message }}</small>
          <small class="error-small" v-if="serverErrors?.length && !errors.length">{{ serverErrors[0] }}</small>
          <small class="error-small" v-if="!errors?.length && !serverErrors?.length">Пароли не совпадают</small>
        </div>
        <small v-if="description && !errors?.length" class="description">{{ description }}</small>
      </div>
    </template>

    <!-- кнопки навигации -->
    <template v-slot:header-left-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:header-right-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:nav-left-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:nav-right-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
  </DatePicker>
</template>

<script>
import { DatePicker } from 'v-calendar'
import { computed, ref, watch } from 'vue'
import { fieldProps } from '@/mixin/props'
import { transformDate } from '@/utils/utils'
import VIcon from '@/components/ui/icon/VIcon'
import { MAX_DATEPICKER } from '@/utils/consts'

export default {
  emits: ['setDate', 'blur', 'input'],
  props: {
    modelValue: {
      type: String
    },
    name: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    classForm: {
      type: String,
      required: false
    },
    serverErrors: {
      type: Array,
      required: false
    },
    mode: {
      type: String,
      required: false,
      default: 'dateTime'
    },
    max: {
      type: String,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    minAdd: {
      type: Number,
      required: false,
      default: 0
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const date = ref(props.modelValue || '')
    const timezones = [
      'Asia/Ashgabat' // +5
    ]

    const getDate = computed(() => {
      return props.modelValue
    })

    const getError = computed(() => {
      return props.errors?.length || props.serverErrors?.length
    })
    const IsLengthInput = computed(() => {
      return date.value
    })

    const getDateState = computed(() => {
      return date.value
    })

    watch(getDate, () => {
      date.value = props.modelValue
    })

    watch(getDateState, (val) => {
      const date = new Date(val)
      emit('update:modelValue', date.toISOString())
    })

    const setDate = () => {
      emit('setDate', date.value, props.name)
      setInput()
    }

    const setBlur = () => {
      emit('blur')
    }

    const setInput = () => {
      emit('input', props.name)
      if (props.serverErrors?.length) props.serverErrors.length = 0
    }

    return {
      IsLengthInput,
      date,
      setDate,
      setBlur,
      setInput,
      getError,
      transformDate,
      timezone: timezones[0],
      MAX_DATEPICKER
    }
  },
  components: {
    DatePicker,
    VIcon
  }
}
</script>
