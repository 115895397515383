import { computed, watch } from 'vue'
import { useStore } from 'vuex'

export function useFilterAndSort (options, filterName, sortName, emit, getItem) {
  const store = useStore()
  const getFilter = computed(() => {
    return store.getters['filter/getFilter']
  })

  watch(getFilter, async (val) => {
    store.commit('pagination/setCurrentPage', 1)
    let filter = ''
    if (!val) {
      if (options.rows) {
        return options.rows
      }
      filterName.value = null
      return await getItem()
    }
    for (let i = 0; i < val.length; i++) {
      filter += `&search[${val[i].type}]=${val[i].value}`
    }
    filterName.value = filter
    await getItem()
  })

  const handlerSort = async (sort) => {
    if (sortName.value === sort) {
      sortName.value = `-${sort}`
    } else {
      sortName.value = sort
    }
    if (options.rows) return emit('sort', sortName.value)
    await getItem()
  }

  return {
    handlerSort,
    getFilter
  }
}
