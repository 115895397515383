<template>
  <div class="pagination">
    <div class="pagination__inner">

      <div class="page" v-if="maxPage > 1">
        Страница {{ currentPage }} <span class="page__max" v-if="maxPage > 1"> из {{ maxPage }} </span>
      </div>

      <div class="pagination__items" v-if="maxPage > 1">
        <VIcon
          :class="['pagination__arrow pagination__back', currentPage === 1 ? 'disabled' : '']"
          width="11"
          height="7"
          icon-name="Arrow"
          @click="setBackPage"
        />

        <div
          :class="['pagination__item pagination__item--start',
           currentPage === 1 ? 'active' : '']"
          v-if="currentPage > 10"
          @click="setPage(1)"
        >1</div>

        <div
          class="pagination__item pagination__item--dotte"
          v-if="currentPage > 10 && maxPage > 10"
          @click="setPage(currentPage - 5)"
        >
          ...
        </div>

        <div
          v-for="page in range"
          :key="page"
          :class="['pagination__item', currentPage === page ? 'active' : '']"
          @click="setPage(page)"
        >
          {{ page }}
        </div>

        <div
          class="pagination__item pagination__item--dotte"
          v-if="currentPage < maxPage - 3 && maxPage > 10 && currentPage + 5 < maxPage"
          @click="setPage(currentPage + 5)"
        >
          ...
        </div>

        <div
          :class="['pagination__item pagination__item--last', currentPage === maxPage ? 'active' : '', maxPage > 10 ? '' : 'hidden']"
          v-if="currentPage !== maxPage && currentPage + 5 < maxPage"
          @click="setPage(maxPage)"
        >
          {{ maxPage }}
        </div>

        <VIcon
          :class="['pagination__arrow pagination__next', +currentPage === +maxPage ? 'disabled' : '']"
          width="11"
          height="7"
          icon-name="Arrow"
          @click="setNextPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import { useStore } from 'vuex'

const emits = defineEmits(['page'])
const store = useStore()
const currentPage = ref(store.getters['pagination/getCurrentPage'])
const maxPage = computed(() => {
  return store.getters['pagination/getMaxPage']
})

const setPage = (page) => {
  currentPage.value = page
  emits('page', currentPage.value)
}

const setNextPage = () => {
  if (+currentPage.value === +maxPage.value) return
  currentPage.value++
  emits('page', currentPage.value)
}

const setBackPage = () => {
  if (currentPage.value === 1) return
  currentPage.value--
  emits('page', currentPage.value)
}

const range = computed(() => {
  let start = Math.max(1, currentPage.value - 4)
  const end = Math.min(start + 9, maxPage.value)

  if (currentPage.value > 1) {
    start = Math.max(1, currentPage.value - 9)
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
})
</script>

<style scoped lang="scss">
@import '~@/styles/_variables.scss';
.pagination {
  margin-top: 35px;
  &__inner {
    display: flex;
    align-items: center;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    margin: 0 10px;

    cursor: pointer;
    color: $color-primary;

    &.active {
      padding: 6px 10px;
      background-color: $color-yellow;
    }
  }

  &__arrow {
    cursor: pointer;

    &.disabled {
      filter: opacity(0.3);
    }
  }

  &__back {
    margin-right: 10px;

    transform: rotate(90deg);
  }

  &__next {
    margin-left: 10px;

    transform: rotate(-90deg);
  }
}
.hidden {
  display: none;
}
.page {
  margin-right: 60px;

  &__max {
    margin-left: 5px;
  }
}
</style>

<style scoped lang="scss">
@import '~@/styles/_variables.scss';
.pagination {
  margin-top: 35px;
  &__inner {
    display: flex;
    align-items: center;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    margin: 0 10px;

    cursor: pointer;
    color: $color-primary;

    &.active {
      padding: 6px 10px;
      background-color: $color-yellow;
    }
  }

  &__arrow {
    cursor: pointer;

    &.disabled {
      filter: opacity(0.3);
    }
  }

  &__back {
    margin-right: 10px;

    transform: rotate(90deg);
  }

  &__next {
    margin-left: 10px;

    transform: rotate(-90deg);
  }
}
.hidden {
  display: none;
}
.page {
  margin-right: 60px;

  &__max {
    margin-left: 5px;
  }
}
</style>
